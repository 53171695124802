.d-dic-menu {
  height: 100%;
  padding: 8px 0;
  background-color: rgba(0,0,0, .008);
  position: relative;
}

.d-dic-search {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.d-dic-fileds {
  width: inherit;
  height: calc(100% - 34px);
  margin-top: 34px;
  box-sizing: border-box;
  overflow-y: auto;
  background-color: #f9f9f9;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      line-height: 40px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      padding: 0 10px;
      border-bottom: 1px solid #f3f3f3;

      &:last-child {
        border-bottom: 0;
      }

      &:hover {
        // background-color: #e6f7ff;
        color: #1890ff;
        cursor: pointer;
      }

    }

  }
}

.d-field-selected {
  background-color: #e6f7ff;
  color: #1890ff;
}

.d-group-title {
  margin: 15px 0;
}