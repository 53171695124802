.d-error-log-remark {
  max-height: 400px;
  width: 100%;
  padding: 5px;
  border-radius: 3px;
  overflow: auto;
  background-color: #fafafa;
  color: #9b9b9b;
}

.d-error-log-content {
  max-height: 400px;
  width: 100%;
  padding: 15px;
  border-radius: 3px;
  overflow: auto;
  background-color: #fafafa;
  color: #9b9b9b;
}