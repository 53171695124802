.d-gateway {

  display: flex;
  width: inherit;
  height: inherit;
  padding: 15px 5px;
  box-sizing: border-box;

  &-left {
    min-width: 200px;
    max-width: 360px;
    width: 40%;
    padding: 0 8px;
    overflow: auto;
  }

  &-right {
    flex: 1;
    padding: 0 8px;
    overflow: auto;
  }

}

.d-greeting {
  font-size: 20px;
  margin-bottom: 15px;
  display: flex;

  &-left {
    flex: 1 1 0;
  }

  &-right {
    // width: 400px;
    text-align: right;
  }

}

.d-file-url {
  padding: 5px;
  background-color: rgba(0, 0, 0, .01);
  color: rgba(0, 0, 0, .3);
  position: relative;

  &:hover {
    background-color: rgba(0, 0, 0, .03);
    border-radius: 5px;
  }

  &-clear {
    position: absolute;
    right: -36px;
    bottom: 0;
  }
}

.d-tag-download {
  min-width: 85px;
  margin-bottom: 2px;
  margin-right: 0;
}

.d-version-bg-success td {
  background: #E7FBEE;
}

.d-version-bg-pink td {
  background: #FAD0C4;
}